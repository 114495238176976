.live-course-box {
    padding: 0.35vw 0.7vw;
    border-radius: 6px;
}

.LiveCourseBg {
    background-color: #5681EF33;
}

.CourseBg {
    background-color: #00882033;
}

.video-tilte-table {
    font-size: 0.79vw !important;
    margin: 0;
    padding: 0.5vw 1.5vw 0 1.5vw;
}

.parent-link-quiz-search {
    width: 100%;
}

#link-quiz-search::placeholder {
    display: block !important;
}

#link-quiz-search {
    padding: 0.6vw 2.14vw 0.6vw 2.9vw;
}

.parent-link-quiz-search img {
    width: 1.25vw !important;
    margin: 0px 1vw;
    height: 1.25vw;
    position: absolute;
}

.quiz-list-ul p {
    font-size: 0.85vw;
}

.quiz-list-ul img {
    width: 1vw;
    height: 1vw;
    cursor: pointer;
}

.no-quiz h2 {
    background-color: #b5c0cc;
    width: 100%;
    padding: 1vw 0;
    font-size: 1vw;
    font-weight: 500;
}

.quiz-loader {
    height: 3vw;
    width: 100%;
}
@keyframes backgroundFade {
    0% {
        background-color: transparent;
    }
    100% {
        background-color: rgb(185, 240, 185);
    }
}

.selectedQuiz {
    animation: backgroundFade 0.5s ease-in-out forwards;
    background-color: rgb(185, 240, 185);
}
.quiz-list-mb{
    margin-bottom: 1vw !important;
}

.share-through-con.disabled{
    cursor:not-allowed;
    opacity: 0.4;
  }
  
.share-through-con.disabled svg,
.share-through-con.disabled path,
.share-through-con.disabled button{
cursor: not-allowed !important;
}
  
.share-flex.mt-3.disabled{
cursor:not-allowed;
opacity: 0.4;
}
.share-flex.mt-3.disabled svg,
.share-flex.mt-3.disabled path,
.share-flex.mt-3.disabled button{
cursor: not-allowed !important;
}

.video-upload-progress{
    display: flex;
    align-items: center;
    gap: 2vw;
  }
  
  .video-upload-progress p{
    text-align: center;
    font-size: 1.6vw;
    font-weight: 500;
  }