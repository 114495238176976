.questions-created-count-con{
    padding: 1.6vw;
  }
  .questions-created-count-con > div{
    background-color:var(--icons-color);
    padding: 1vw ;
  }
  
  .questions-created-count-con > div > p{
    color: #ffffff;
    font-size: 1vw;
  }