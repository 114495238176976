.tenent-setting-icon>div {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
}
.setting-icon-tab{
    width: 48%;
}

.tenant-input {
    display: flex;
    justify-content: space-between;
    gap: 3vw;
}
#course-expiry-inp{
    padding: 0.5vw 1vw !important;
    width: 100%;
    font-size: 0.8vw;
}